import {lendersData} from "@utils/data";

const LendersLove = () => {
  return (
    <div className="lenders-love-holder">
      <div className="uni-width">
        <div className="lenders-love-first">
          <div className="lenders-love-info header-width text-white">
            <p className="heading-4 inter-display">Lenders love Lendsqr!</p>
            <p className="text-white-trans body-lg-regular mt-20">
              Our strong capability and robust platform enable us to offer
              support across the entire lending lifecycle.
            </p>

            <div className="button-holder mt-32">
              <a
                className="btn btn-primary mr-10 body-md-bold"
                href="https://app.lendsqr.com/signup?utm_source=Lendsqr&utm_medium=web"
                target="_blank"
                rel="noreferrer"
              >
                Start lending with Lendsqr
              </a>
              <a
                className="btn btn-pale right-btn body-md-bold"
                href="https://lsq.li/calendar-joy?utm_source=Lendsqr&utm_medium=web"
                target="_blank"
                rel="noreferrer"
              >
                Contact Sales
              </a>
            </div>
          </div>

          <img
            src="/assets/images/three-clouds.svg"
            alt="three clouds"
            className="cloud-image"
          />
        </div>

        <div className="lenders-love-row mt-48">
          {lendersData.map((lender, index) => {
            return (
              <div
                key={lender.title}
                className={`lenders-love-box ${
                  index <= 2 ? "lenders-love-small mb-24" : "lenders-love-large"
                }`}
                style={{backgroundColor: lender.color}}
              >
                <div className="lender-box-info">
                  <p className="text-white body-lg-bold">{lender.title}</p>
                  <p className="text-white-trans mt-8 body-md-regular">
                    {lender.description}
                  </p>
                </div>

                <p className="lenders-love-shadow-text ">{lender.number}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export {LendersLove};
