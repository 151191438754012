import {EmptyState} from "./EmptyState";
import {useBlogPosts} from "../../../queries/blog";

const LatestBlog = ({background}: {background?: string}) => {
  const {data} = useBlogPosts();
  if (!data) {
    return (
      <div className="py-20 text-center bg-white-200">Loading blogs...</div>
    );
  }

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div className="blog-blog-holder">
      <div className="blog-inner uni-width">
        <h2 className="text-brand-blue header-width heading-4 inter-display">
          The Lendian Chronicles
        </h2>
        <p className="mt-20 text-secondary header-width mb-48 body-lg-regular">
          Expertly crafted insights on everything about lending and how lenders
          are using Lendsqr.
        </p>

        <div className="blog-row">
          {data?.map((item: any) => {
            const postDate = new Date(item.date);

            return (
              <div className="blog-cell card-shadow" key={item.id}>
                <div className="blog-holder">
                  <a href={item.link} target="_blank" rel="noreferrer">
                    <img
                      src={item.thumbnail_url}
                      alt={item.title.rendered.slice(0, 40)}
                      loading="lazy"
                    />
                  </a>
                </div>

                <div className="blog-info">
                  <p className="cap-title five-14">Lending</p>

                  <a href={item.link} target="_blank" rel="noreferrer">
                    <p className="text-brand-blue mt-8 heading-6 inter-display">
                      {item.title.rendered}
                    </p>
                  </a>

                  <p
                    dangerouslySetInnerHTML={{
                      __html: item.excerpt.rendered,
                    }}
                    className="four-16 text-secondary mt-8 three-lines"
                  ></p>

                  <p className="text-tetiary four-14 mt-24">{`${
                    months[postDate.getMonth()]
                  } ${postDate.getDate()}, ${postDate.getFullYear()}`}</p>
                  {/* <p className="four-16 text-secondary mt-8">{item.excerpt.rendered}</p> */}
                </div>
              </div>
            );
          })}
        </div>

        <a
          className="btn btn-primary mt-48 center-self"
          href="https://blog.lendsqr.com/"
          target="_blank"
          rel="noreferrer"
        >
          Visit our blog
        </a>

        {!data && (
          <EmptyState desc="We cannot find your search. Please search again." />
        )}
      </div>
    </div>
  );
};

export {LatestBlog};
