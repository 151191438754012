import {useEffect, useRef, useState} from "react";

type WebInfoProp = {
  inverted?: boolean
  isPadding?: boolean
  data: Array<WebInfoTypeData>
  title: string
  description: string
  cap?: string
  image?: string
}

type WebInfoTypeData = {
  title: string
  description: string
  url: string
  internal?: boolean
  image?: string
}

const WebInfo = ({
  inverted,
  isPadding,
  data,
  title,
  description,
  cap,
  image,
}: WebInfoProp) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [webWidth, setWebWidth] = useState(0);
  const webRef = useRef(null);

  useEffect(() => {
    const timeInterval = setInterval(() => {
      if (webWidth >= 100) {
        clearInterval(webWidth);
      }

      setWebWidth((prevWidth) => prevWidth + 0.1);
    }, 10);

    return () => clearInterval(timeInterval);
  }, []);

  useEffect(() => {
    if (webWidth >= 100) {
      if (activeIndex < data.length - 1) {
        setActiveIndex((prevIndex) => prevIndex + 1);
        setWebWidth(0);
      } else {
        setActiveIndex(-1);
      }
    }
  }, [webWidth]);

  return (
    <div
      className={`web-info-holder flex-center uni-width ${
        isPadding && "padding-120"
      }`}
    >
      <div className={`web-inner ${inverted && "row-reversed"}`}>
        <div className={`web-first ${!inverted && "web-first-right"}`}>
          <img src={data[activeIndex]?.image ?? image} alt="visual image" />
        </div>

        <div className={`web-second flex-one ${inverted && "web-first-right"}`}>
          {cap && <p className="cap-title six-12 text-tiffany">{cap}</p>}

          <p className="text-brand-blue mt-20 web-info-header heading-5 inter-display">
            {title}
          </p>

          <p className="text-secondary mt-20 web-info-header body-md-regular">
            {description}
          </p>

          <div className="web-info-doc mt-40">
            {data.map((d, index) => {
              return (
                <div
                  key={d.title}
                  className={`web-info-cell ${
                    index !== data.length - 1 && "mb-24"
                  }`}
                >
                  <p
                    className={`web-info-cell-title mb-12 cursor-pointer body-md-bold
                                            ${
                                              index === activeIndex
                                                ? "text-primary"
                                                : "text-disabled"
                                            }
                                        `}
                    onClick={() => {
                      setActiveIndex(index);
                      setWebWidth(0);
                    }}
                  >
                    {d.title}
                  </p>

                  {index === activeIndex && (
                    <div className="web-lower-animate">
                      <div className="web-info-cell-lower ">
                        <p className="text-secondary four-16">
                          {d.description}
                        </p>

                        <a
                          className="web-learn text-link six-16 mt-24 mb-32"
                          href={d.url}
                          target={d.internal ? "_self" : "_blank"}
                          rel="noreferrer"
                        >
                          <span className="mr-8 ">Learn more</span>{" "}
                          <img
                            src="/assets/icons/green-arrow.svg"
                            width={13.33}
                            height={10}
                          />
                        </a>
                      </div>

                      <div className="web-progress" ref={webRef}>
                        <div
                          className="web-time"
                          style={{width: `${webWidth}%`}}
                        ></div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export {WebInfo};
