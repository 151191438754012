import {useQuery} from "@tanstack/react-query";

const generateRandomNumbers = (max: number): number[] => {
  const randomNumbers = new Set<number>();

  while (randomNumbers.size < 3) {
    const randomNumber = Math.floor(Math.random() * max) + 1;
    randomNumbers.add(randomNumber);
  }

  return Array.from(randomNumbers);
};

const getBlogPosts = async () => {
  const response = await fetch(
    "https://blog.lendsqr.com/wp-json/wp/v2/posts?per_page=100&page=1"
  );

  const data = await response.json();

  // Generate 3 random indices
  const randomNumbers = generateRandomNumbers(data.length);

  // Fetch thumbnails for the randomly selected posts
  const thumbnail_urls = await Promise.all(
    randomNumbers.map((index) => getMediaID(data[index - 1].featured_media))
  );

  // Filter data to include only the randomly selected posts
  const selectedPosts = randomNumbers.map((index, i) => {
    const post = data[index - 1];
    post.thumbnail_url = thumbnail_urls[i];
    return post;
  });

  return selectedPosts;
};

export const getMediaID = async (id: number) => {
  const response = await fetch(
    `https://blog.lendsqr.com/wp-json/wp/v2/media/${id}`
  );

  const data = await response.json();

  return data.guid.rendered;
};

const useBlogPosts = () => {
  return useQuery(["getBlogPosts"], getBlogPosts, {
    retry: 2,
  });
};

export {useBlogPosts};
