import {boxData} from "@utils/data";

const Masterd = () => {
  return (
    <div className="masterd-holder">
      <div className="masterd-inner uni-width">
        <div className="masterd-one flex-one">
          <div className="masterd-one-inner">
            <p className="cap-title six-12 text-tiffany">WHY LENDSQR?</p>

            <p className="text-brand-blue mt-20 heading-4 inter-display">
              We&apos;ve overcome every challenge to empower your success.
            </p>

            <p className="text-secondary mt-20 body-lg-regular">
              Having encountered nearly every issue a lender might face, we
              offer comprehensive solutions. By choosing Lendsqr as the backbone
              of your digital lending platform, you are choosing guaranteed
              growth and success.
            </p>

            <div className="masterd-square-holder mt-48">
              {boxData.map((box) => {
                return (
                  <div
                    className={`masterd-square ${box.color}`}
                    key={box.title}
                  >
                    <div className={`masterd-circle ${box.cirlce_color}`}>
                      <img src={box.image} alt="box image" />
                    </div>

                    <p className="text-primary mt-24 body-lg-bold">
                      {box.title}
                    </p>
                    <p className="text-secondary mt-8 body-md-regular">
                      {box.description}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="masterd-two ">
          <img
            src="assets/images/box-illustration.svg"
            alt="box-illustration"
            className="box-image"
          />
        </div>
      </div>
    </div>
  );
};

export {Masterd};
