import {capabilitiesList, capabilitiesData} from "@utils/data";
import {WebInfo} from "../../general/WebInfo";
import {useState} from "react";

const Capabilities = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [prevActive, setPrevActive] = useState(-1);

  return (
    <div className="capabilities-holder">
      <div className="capabilities-inner uni-width">
        <p className="text-brand-blue header-width heading-4 inter-display">
          All the capabilities <br />
          you need to simplify your lending process
        </p>

        <p className="text-secondary header-width mt-20 mb-48 body-lg-regular">
          Our robust end-to-end loan management software enables us to offer
          support throughout the entire lending lifecycle.
        </p>

        <div className="capabilities-nav mb-48">
          {capabilitiesList.map((cap, index) => {
            return (
              <div
                className={`capability ${
                  index === activeIndex && "capability-active"
                }`}
                key={cap}
                onClick={() => {
                  setActiveIndex(index);
                  setPrevActive(activeIndex);
                }}
              >
                {cap}
              </div>
            );
          })}
        </div>

        <div className="capabilities-nav-mobile mb-48">
          <div
            className="capabilities-active-mob"
            onClick={() => {
              setShowDropdown(!showDropdown);
            }}
          >
            <p className="five-14 text-primary">
              {capabilitiesList[activeIndex]}
            </p>
            <img src="/assets/icons/down-icon-new.svg" alt="down icon" />
          </div>

          {showDropdown && (
            <div className="capabilities-mob-dropdown">
              {capabilitiesList.map((cap, index) => {
                return (
                  <div
                    className="capability-mob five-14 text-primary"
                    key={cap}
                    onClick={() => {
                      setActiveIndex(index);
                      setPrevActive(activeIndex);
                      setShowDropdown(false);
                    }}
                  >
                    {cap}
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div className="capabilities-row">
          {capabilitiesData.map((data, index) => {
            if (index === activeIndex) {
              return (
                <div
                  className={`${
                    index === activeIndex &&
                    activeIndex > prevActive &&
                    "capabilities-next"
                  }
                      ${
                        index === activeIndex &&
                        activeIndex < prevActive &&
                        "capabilities-prev"
                      }
                     `}
                  key={data.title}
                >
                  <WebInfo
                    data={data.data}
                    title={data.title}
                    description={data.description}
                    cap={data.cap}
                    image={data.image}
                  />
                </div>
              );
            }

            if (index === prevActive) {
              return (
                <div
                  className={`${
                    activeIndex > prevActive && "capabilities-prev-next"
                  }
                      ${activeIndex < prevActive && "capabilities-prev-prev"}
                    `}
                  key={data.title}
                >
                  <WebInfo
                    data={data.data}
                    title={data.title}
                    description={data.description}
                    cap={data.cap}
                    image={data.image}
                  />
                </div>
              );
            }

            return "";
          })}
        </div>
      </div>
    </div>
  );
};

export {Capabilities};
